import request from '@/utils/request'

export function getHomeLive() {
    return request({
        url: '/ich/live/pc/home/live',
        method: 'get',
    })
}


// 获得直播分页
export function getLivePage(query) {
    return request({
        url: '/ich/live/page',
        method: 'get',
        params: query
    })
}

// 获得直播分页
export function getLiveDetail(id) {
    return request({
        url: '/ich/live/get?id='+id,
        method: 'get',
        
    })
}
