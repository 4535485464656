<template>
    <div class="live-container">
        <breadcrumb></breadcrumb>
        <div class="container-title">
            播·非遗
        </div>
        <div class="live-category-box">
            <category @change="changeCategory" :categorys="categorys"></category>
        </div>
        <list :list="list" imgHeight="220px" @toDetail="toDetail">
            <template #title-img-mask>
                <img class="play-icon" src="../../assets/images/play.png" alt="">
            </template>
            <template #default="{ row }">
                <div>直播时间：{{ formatDate(row.startTime, 'yyyy-MM-dd') }}</div>
            </template>
        </list>
        <!-- 分页组件 -->
        <pagination v-show="total > 0" :total="total" :page.sync="pageNo" :limit.sync="pageSize" @pagination="getList" />
    </div>
</template>

<script>
import {
    getLivePage
} from "@/api/live/live";
export default {
    name: 'liveList',
    data() {
        return {
            categoryIndex: 0,//分类下标
            //分类
            categorys: [{
                name: '全部',
                status: ''
            }, {
                name: '正在直播',
                status: 1
            }, {
                name: '直播回放',
                status: 2
            }],
            pageNo: 1,//页码
            pageSize: 9,//每页条数
            total: 0,//总数量
            list: [],//列表
        }
    },
    methods: {
        /**
         * 详情
         */
        toDetail(item) {
            // console.log(item)
            this.$router.push({path:'./detail/'+item.id})
            // this.$router.push({})
        },
        /**
         * 获取列表
         */
        getList() {
            let status = this.categorys[this.categoryIndex].status//当前分类
            getLivePage({
                pageNo: this.pageNo,
                pageSize: this.pageSize,
                type:status
            }).then(res => {
                if (res.code == 0) {
                    this.list = res.data.list
                    this.total = res.data.total
                }
            })
        },
        /**
         * 选择分类
         */
        changeCategory(index) {
            this.categoryIndex = index
            this.getList()
        }
    },
    created() {
        //获取列表
        this.getList()
    }
}
</script>

<style lang="scss" scoped>
.live-container {
    width: 1200px;
    margin: 0 auto;

    .container-title {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 36px;
        color: #181B1C;
        font-family: XiaoWei-Regular;
    }

    .play-icon {
        width: 40px;
        height: 40px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0;
        transition: .3s;
    }

    .list-item:hover {
        .play-icon {
            opacity: 1;
        }
    }

}
</style>